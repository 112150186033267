.bottom-bar {
    height: 70px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    z-index: 2;
    background-color: white;
}

.position-fixed-top {
    position: fixed;
    top: 0;
    animation: fadein 1s;
}

.bottom-bar-left {
    width: 25%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    border-right: 2px solid;
    border-bottom: 2px solid;
    padding: 5px;
}

.bottom-bar-center {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-bottom: 2px solid;
}

.bottom-bar-center-item:hover {
    cursor: pointer;
}

.bottom-bar-right {
    width: 25%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-bottom: 2px solid;
}

.bottom-bar-left-item {
    padding: 20px;
}
.fa-twitter:hover {
    background-color: #00ACED;
    color: honeydew;
    cursor: pointer;
    border-radius: 5px;
}

.fa-facebook:hover {
    background-color: #3B5998;
    color: honeydew;
    cursor: pointer;
    border-radius: 5px;
}

.fa-linkedin:hover {
    background-color: #007BB6;
    color: honeydew;
    cursor: pointer;
    border-radius: 5px;
}

.fa-instagram:hover {
    background-color: #517FA4;
    color: honeydew;
    cursor: pointer;
    border-radius: 5px;
}

.fa-whatsapp:hover {
    background-color: #07BC4C;
    color: honeydew;
    cursor: pointer;
    border-radius: 5px;
}

.envelope-o {
    border-left: 2px solid;
    border-right: 2px solid;
}

.bottom-bar-right-item {
    padding: 23px;
}
.bottom-bar-right-item:hover {
    cursor: pointer;
}

.logo {
    background-image: url("~assets/img/icon.jpg");
    height: 69px;
    width: 140px;
    background-size: contain;
    background-repeat: no-repeat;
    transition: all .5s ease-in-out;

    &:hover {
        transition: all .5s ease-in-out;
        background-image: url("~assets/img/logo.jpg");
    }
}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}
    
/* Firefox < 16 */
@-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}
    
/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}
    
/* Internet Explorer */
@-ms-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}
    
/* Opera < 12.1 */
@-o-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}


@media only screen and (max-width: 600px) {
    .bottom-bar-center-item {
      display: none;
    }
}

@media only screen and (max-width: 600px) {
    .logo {
        display: flex;
    }
}