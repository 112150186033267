
.top-bar {
    height: 35px;
    width: 100%;
    top: 0;
    left: 0;
    border-bottom: 2px solid #e3e3e3;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 15px;
    padding-right: 15px;
    transition: all .5s ease-in-out;
}

.tb-right {
    display: flex;
    justify-content: flex-end;
}

.tb-text {
    font-size: 12px;
    line-height: 36px;
}