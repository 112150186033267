.bottom-section-footer {
    height: 170px;
    width: 100%;
    background: #efefef;
    padding-left: 100px;
    padding-right: 100px;
    bottom: 0;
}

.bottom-section-footer-top {
    height: 100px;
    width: 100%;
    border-bottom: 2px solid #ccc !important;

    align-items: center;
    display: flex;
    justify-content: center;
}

.bottom-section-footer-bottom {
    height: 70px;
    width: 100%;
    font-size: 11px;
    align-items: center;
    display: flex;
    justify-content: space-between;
}