.container {
    padding: 40px;
    background: #419be0;
  }
  
  .slick-slide img {
    margin: auto;
  }
  
  .parallex {
    transition: all .5s ease-in-out;
  }
  
  .object-fit-cover {
    object-fit: cover;
  }

  .slick-image {
    height: 530px;
    object-fit: cover;
    filter: brightness(80%);
  }

  .landing-page-slide-show .slick-image {
    filter: brightness(50%);
  }

  .slide-center-section {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
  }

  .caption-title {
    font-size: 50px!important;
    line-height: 62px!important;
    font-family: Lora;
    font-weight: 400;
    font-style: normal;
    text-shadow: 1px 1px 3px rgba(0,0,0,0.1);
    position: absolute;
    top: 142px;
    transition: all .5s ease-in-out;

    &:hover {
      color: transparent;
      transition: all .5s ease-in-out;
    }
  }

  .caption-center-text {
    font-family: Karla;
    position: absolute;
    font-weight: 400;
    top: 230px;
    transition: all .5s ease-in-out;

    &:hover {
      color: transparent;
      transition: all .5s ease-in-out;
    }
  }

  .read-more-button {
    background-color: #fff;
    color: #222!important;
    font-size: 12px;
    line-height: 20px;
    padding: 14px 26px;
    margin: 0 10px 20px 0;
    font-weight: bold;
    transition: all .3s ease-out;
    position: relative;
    letter-spacing: 1px;
    max-width: 100%;
    top: 80px;

    &:hover{
      filter: brightness(80%);
      cursor: pointer;
    }
  }