.principles-page {
    //border: 1px solid red;
    width: 100%;
    height: 1050px;
    font-family: cursive;
}

.top-section {
    width: 100%;
    height: 160px;
    background-color: black;

    display: grid;
    align-items: center;
    justify-content: center;
    padding-top: 30px;
    padding-bottom: 30px;
    text-align: center;
}

.title {
    height: 30px;
    width: 100%;

    font-size: 24px !important;
    color: white;
    font-family: "Karla"
}

.sub-title {
    height: 30px;
    width: 100%;

    font-size: 20px;
    line-height: 42px;
    color: #bababa;
    align-self: baseline;
    font-family: "Karla"
}

.principles-section {
    //border: 1px solid red;
    width: 100%;
    height: 70%;
    padding-left: 100px;
    padding-right: 100px;
    padding-top: 50px;
    height: 900px;

    display: flex;
    font-family: "auto";
}

.principles-section-left {
    width: 100%;
    padding-right: 10px;

    font-weight: 400;
    font-style: normal;
    font-size: 25px;
    line-height: 40px;
    font-family: "lora";
}

.principles-section-right {
    height: 30px;
    width: 100%;
}

.principles-section-right-title {
    margin-bottom: 30px;
    float: left;
    border-bottom: 2px solid #222222;
    font-size: 18px;
    color: #dd9933;
    font-family: "Karla"
}

.principles-section-right-body {
    font-family: Karla;
    font-weight: 400;
    font-size: 14px;

    padding-top: 60px;
}
